<template>
  <div
    id="labor-recruitment-id"
  >
    <div class="scroll-table table-model">
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- SỐ LƯỢNG (NGƯỜI)-->
          <span v-if="props.column.field === 'worker'">
            <b-form-input
              v-model="props.row.worker"
              type="number"
              @change="handleCountWorker($event, props.row.collectJobId, props.row.femaleWorker)"
            />
          </span>

          <!-- TRONG ĐÓ NỮ (NGƯỜI)-->
          <span v-else-if="props.column.field === 'femaleWorker'">
            <b-form-input
              v-model="props.row.femaleWorker"
              type="number"
              @change="handleCountFemaleWorker($event, props.row.collectJobId, props.row.worker)"
            />
          </span>

        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BFormInput,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BFormInput,
  },
  props: {
    collectBussinessId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataList: [],
      columns: [
        {
          label: 'MÃ NGHỀ CẤP 2',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN GỌI NGHỀ NGHIỆP',
          field: 'name',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG (NGƯỜI)',
          field: 'worker',
          sortable: false,
        },
        {
          label: 'TRONG ĐÓ NỮ (NGƯỜI)',
          field: 'femaleWorker',
          sortable: false,
        },
      ],
      femaleWorker: 0,
      worker: 0,
    }
  },
  created() {
    this.fetchData(this.collectBussinessId)
  },
  methods: {
    // Fetch data
    async fetchData(collectBussinessId) {
      const param = {
        collectBussinessId,
      }
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_LABOR_RECRUITMENT_ID, {
        params: param,
      })
      this.dataList = data
      this.$hideLoading()
    },

    async handleCountWorker(value, id, femaleWorker) {
      this.worker = value
      const model = {
        collectBussinessId: this.collectBussinessId,
        collectJobId: id,
        worker: Number(this.worker),
        femaleWorker: Number(femaleWorker),
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_LABOR_RECRUITMENT_ID, model)
    },
    async handleCountFemaleWorker(value, id, worker) {
      this.femaleWorker = value
      const model = {
        collectBussinessId: this.collectBussinessId,
        collectJobId: id,
        femaleWorker: Number(this.femaleWorker),
        worker: Number(worker),
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_LABOR_RECRUITMENT_ID, model)
    },
  },
}
</script>

<style lang="scss">
.scroll-table {
  overflow-y: scroll;
  height: 500px;
}
</style>
